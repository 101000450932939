
.nameTag {
  
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 5;
  margin: 0px;
  line-height: 1;
  z-index: 75;
}

.center_all {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  position: absolute;
  color: white;
  overflow: hidden;
}

.bg-height{
  height: 100%;
}

.CenterTile {
  font-family: LeagueSpartan-Bold;
}

.aboutTitle {
  font-family: LeagueSpartan-Bold;
  margin-top: 10px;
  
}

#aboutCard{
  background: rgba(255, 255, 255, 0.856);
}

