@font-face {
  font-family: 'LeagueSpartan-Bold';
  src: local('LeagueSpartan-Bold'), url(./fonts/LeagueSpartan-Bold.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

html{
  background-color: rgb(4, 4, 29);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(4, 4, 29);
}
.h1-name{
  font-size:4rem;
  
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-data {
  background-color: rgb(4, 4, 29);

}

.subTile {
  margin-top: 15px;
}

.navi {
  opacity: 0;
}

.slanted {
    background-color:red;
    height:400px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 56%, 0 100%);
    clip-path: polygon(0 0, -1000% 0, 300% 100%, 0 100%);
}
#tsparticles{
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.body-particles{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.homePage {
  background-image: url('orng-a-logo.png');
  height: 15rem;
  width: 15rem;
  background-repeat: no-repeat;
  background-size: 15rem 15rem;
  background-position: center;
  margin-top: 5%;
  left: 40%;
  position:relative;
  opacity: .5;
  z-index: 50;
 
}

.aboutPage{
  background-image: url('orng-a-logo.png');
  background-repeat: no-repeat;
  background-size: 15rem 15rem;
  background-position: center;
  left: 40%;
  z-index: 50;
}

.button-div {
  margin-bottom: 10px;
}

.nav-text {
  font-family: LeagueSpartan-Bold ;
}

.projectTitle {
  font-family: LeagueSpartan-Bold ;
  color: white;
}

.nav-link {
  margin-right: 20px;
}